<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-12 text-center pt-5 pb-4">
						<h2>{{ $t('message.mp_title2') }}</h2>
					</div>
				</div>
				<div class="row pb-1 pt-4">
					<div class="col-12 col-md-6 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/icons/ic_satisfacao.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.why_title1') }}</h3>
							<p>{{ $t('message.why_text1') }}</p>
						</div>
					</div>
					<div class="col-12 col-md-6 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/icons/ic_qualidade.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.why_title2') }}</h3>
							<p>{{ $t('message.why_text2') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="row pb-5 pt-1">
					<div class="col-12 col-md-6 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/icons/ic_precos.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.why_title3') }}</h3>
							<p>{{ $t('message.why_text3') }}</p>
						</div>
					</div>
					<div class="col-12 col-md-6 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/icons/ic_renting.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.why_title4') }}</h3>
							<p>{{ $t('message.why_text4') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name       : 'WhyZone',
	components : {},
	data       : function () {
		return {};
	},
	methods    : {}
};
</script>
